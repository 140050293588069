import React, { useEffect, useState } from "react";

import Card from "../../components/Cards";
import Layout from "../../components/Layout";
import ModalRegister from "./components/ModalRegister";
import TitleC6 from "../../components/Typography/TitleC6";
import InputSearch from "../../components/Input/InputSearch";

import { BiWindowClose } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { FaRegFileAlt } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { MdCompare } from "react-icons/md";
import { RiFileUploadLine } from "react-icons/ri";
import Paginate from "../../components/Paginate/Paginate";
import moment from "moment";
import ModalDetails from "./components/ModalDetails";
import { pillConfig } from "./utils/pillConfig";
import ModalRemove from "./components/ModalRemove";
import requests from "../../services/requests";
import SEO from "../../components/SEO";

const SpecificationOperation = () => {
    const [activeOptions, setActiveOptions] = useState();
    const [isEditMode, setIsEditMode] = useState(false);
    const [openModalNewSpec, setOpenModalNewSpec] = useState(false);
    const [openModalDetails, setOpenModalDetails] = useState(false);
    const [openModalRemove, setOpenModalRemove] = useState(false);
    const [loadingRemove, setLoadingRemove] = useState(false);
    const [detailsData, setDetailsData] = useState();
    const [removeId, setRemoveId] = useState();

    // Data
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    // Paginate
    const [action, setAction] = useState(1);
    const [meta, setMeta] = useState({
        current_page: 1,
    });

    // Search
    const [searchString, setSearchString] = useState("");

    const onChangeSearchString = e => {
        let search = e.target.value.trim();
        setSearchString("");
        if (search.length > 2) {
            setSearchString(search);
        }
    };

    useEffect(() => {
        loadList();
    }, [action]);

    // useEffect(() => {
    //     console.log(searchString); //TODO - Implements API
    // }, [searchString]);

    const handleRemoveItem = id => {
        //TODO implements remove method
        setTimeout(() => {
            setLoadingRemove(false);
        }, 3000);
        setLoadingRemove(true);
    };

    const handleActiveOption = index => {
        if (index === activeOptions) {
            setActiveOptions(false);
        } else {
            setActiveOptions(index);
        }
    };

    const actionModal = ({ isEditModal, data = undefined }) => {
        if (detailsData) {
            setDetailsData(undefined);
        }
        if (isEditModal && data) {
            setIsEditMode(true);
            setDetailsData(data);
        } else {
            setIsEditMode(false);
        }

        setOpenModalNewSpec(prev => !prev);
    };

    const actionModalDetails = (data = undefined) => {
        if (data) {
            setDetailsData(data);
        }
        setOpenModalDetails(prev => !prev);
    };

    const actionModalRemove = (id = undefined) => {
        if (id) {
            setRemoveId(id);
        }
        setOpenModalRemove(prev => !prev);
    };

    const loadList = () => {
        setLoadingData(true);

        requests.listOperation({}, meta.current_page, 10).then(response => {
            setData(response.data);
            setMeta(response.meta);
            setLoadingData(false);
        });
    };

    const renderOptions = (index, data) => {
        return (
            <td className=" w-40 ">
                <div className="flex py-4 gap-5 float-right mr-2">
                    {activeOptions === index && (
                        <div className="flex gap-2">
                            <AiOutlineEye className="cursor-pointer" onClick={() => actionModalDetails(data)} size={20} />
                            <FiEdit
                                className="cursor-pointer"
                                size={20}
                                onClick={() => actionModal({ isEditModal: true, data })}
                            />
                            <MdCompare className="cursor-pointer" size={20} />
                            <RiFileUploadLine className="cursor-pointer" size={20} />
                            <BiWindowClose
                                className="cursor-pointer"
                                size={20}
                                onClick={() => actionModalRemove(data.identifier)}
                            />
                        </div>
                    )}
                    <BsThreeDots className="cursor-pointer" size={20} onClick={() => handleActiveOption(index)} />
                </div>
            </td>
        );
    };

    return (
        <Layout>
            <SEO title="Especificação Operacional" />

            <ModalRemove
                loading={loadingRemove}
                handleRemove={handleRemoveItem}
                actionModal={actionModalRemove}
                openModal={openModalRemove}
                id={removeId}
            />
            <ModalRegister
                isEditMode={isEditMode}
                actionModal={() => actionModal({ isEditModal: false })}
                openModal={openModalNewSpec}
                data={detailsData ? detailsData : undefined}
            />
            <ModalDetails
                data={detailsData}
                actionModal={() => actionModalDetails()}
                openModal={openModalDetails}
            />
            <div className="p-4">
                <Card>
                    <TitleC6> Especificação de operação</TitleC6>
                    <div className="flex border-b mt-8 border-C2 pb-4 justify-between">
                        <div className="h-8 w-1/4">
                            <InputSearch placeholder="CNPJ, Razão social, Nome fantasia" onChange={onChangeSearchString} />
                        </div>
                        <button
                            className="flex gap-2 bg-roxo_oficial text-white px-4 py-2 rounded-md items-center"
                            onClick={() => { actionModal({ isEditModal: false }); }}>
                            <FaRegFileAlt />
                            Cadastro de Empresa
                        </button>
                    </div>
                    <table className="mt-8 table-auto w-full">
                        <thead className="border-b border-roxo_oficial">
                            <tr>
                                <th className="text-left max-w-56 text-roxo_oficial font-normal">Cliente</th>
                                <th className="text-left max-w-56 text-roxo_oficial font-normal">CNPJ</th>
                                <th className="text-left min-w-5 text-roxo_oficial font-normal">Razão Social</th>
                                <th className="text-left min-w-5 text-roxo_oficial font-normal">Nome Fantasia</th>
                                <th className="text-left min-w-5 text-roxo_oficial font-normal">Versão</th>
                                <th className="text-left max-w-56 text-roxo_oficial font-normal">Alteração</th>
                                <th className="text-left max-w-56 text-roxo_oficial font-normal">Data de Cadastro</th>
                                <th className="text-left max-w-56 text-roxo_oficial font-normal">Status</th>
                                <th className="float-right">
                                    <BsThreeDots size={20} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.length > 0 &&
                                data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="py-4 text-left font-bold text-roxo_oficial">
                                                {item.registration.companyName}
                                            </td>
                                            <td>{item.registration.companyOperationDocumentNumber}</td>
                                            <td>{item.registration.companyName}</td>
                                            <td>{item.registration.companyFantasyName}</td>
                                            <td>{item.version}</td>
                                            <td>Sim</td>
                                            <td>{moment(item.createdAt).format("DD/MM/YYYY HH:mm")}</td>
                                            <td>{pillConfig(item.status)}</td>
                                            {renderOptions(4, item)}
                                        </tr>
                                    );
                                })
                            }
                            {data?.length === 0 && <tr>
                                <td colSpan="10" className="text-center pt-4">Sem dados para listar</td>
                            </tr>}
                        </tbody>
                    </table>
                    {meta.total_pages > 1 && (
                        <Paginate
                            meta={meta}
                            setMeta={setMeta}
                            action={action}
                            setAction={setAction}
                            showDetails={true}
                        />
                    )}
                </Card>
            </div>
        </Layout>
    );
};

export default SpecificationOperation;
